function slider(src) {
  let image = document.querySelector("body > section > div > div.image");

  image.style.backgroundImage = `url(/images/home-slider/${src})`;

  image.classList.toggle("active");

  setTimeout(() => {
    image.classList.toggle("active")
  }, 3000)
}

let imageSource = ["slider-img-1.webp", "slider-img-2.webp", "slider-img-3.webp", "slider-img-4.webp", "slider-img-0.webp", "company-front.webp"];

setInterval(() => {
  if (!imageSource.length) {
    imageSource = ["slider-img-1.webp", "slider-img-2.webp", "slider-img-3.webp", "slider-img-4.webp", "slider-img-0.webp", "company-front.webp"];
  }

  slider(imageSource.shift());
}, 3900)

window.onload = () => {
  slider(imageSource[imageSource.length - 1])
}