import anime from "animejs";

const wave1 = "M0,96L48,106.7C96,117,192,139,288,122.7C384,107,480,53,576,48C672,43,768,85,864,128C960,171,1056,213,1152,218.7C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
  wave2 = "M0,96L48,112C96,128,192,160,288,192C384,224,480,256,576,250.7C672,245,768,203,864,197.3C960,192,1056,224,1152,250.7C1248,277,1344,299,1392,309.3L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
  wave3 = "M0,64L48,85.3C96,107,192,149,288,160C384,171,480,149,576,122.7C672,96,768,64,864,85.3C960,107,1056,181,1152,197.3C1248,213,1344,171,1392,149.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
  wave4 = "M0,224L48,208C96,192,192,160,288,165.3C384,171,480,213,576,224C672,235,768,213,864,213.3C960,213,1056,235,1152,218.7C1248,203,1344,149,1392,122.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z";

anime({
  targets: '.wave-top > path',
  easing: 'linear',
  duration: 9500,
  loop: true,
  d: [
    {value: [wave1, wave2]},
    {value: wave3},
    {value: wave4},
    {value: wave1},
  ],
});